import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuillEditor = _resolveComponent("QuillEditor")!

  return (_openBlock(), _createBlock(_component_QuillEditor, {
    id: "phxg-editor",
    theme: "snow",
    modules: _ctx.modules,
    toolbar: "essential"
  }, null, 8, ["modules"]))
}