import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import About from '@/views/About.vue'
import Forgot from '@/views/Forgot.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Reset from '@/views/Reset.vue'
import { AdminRoutes } from '@/modules/admin/routes'
import { BlogRoutes } from '@/modules/blog/routes'
import { ForumRoutes } from '@/modules/forum/routes'
import { TheaterRoutes } from '@/modules/theater/routes'
import { UserRoutes } from '@/modules/user/routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/reset/:token',
    name: 'Reset',
    component: Reset
  },
  ...AdminRoutes,
  ...BlogRoutes,
  ...ForumRoutes,
  ...TheaterRoutes,
  ...UserRoutes
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
