import Training from './Training.vue'
import TrainingIndex from './views/Index.vue'
import TrainingView from './views/View.vue'
export const TrainingRoutes = [
  {
    path: '/theater/training',
    component: Training,
    children: [
      {
        path: '',
        name: 'TrainingIndex',
        component: TrainingIndex,
      },
      {
        path: ':id',
        name: 'TrainingView',
        component: TrainingView,
      },
    ]
  }
]