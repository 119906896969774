import Mission from './Mission.vue'
import MissionIndex from './views/Index.vue'
import MissionView from './views/View.vue'
export const MissionRoutes = [
  {
    path: '/theater/mission',
    component: Mission,
    children: [
      {
        path: '',
        name: 'MissionIndex',
        component: MissionIndex,
      },
      {
        path: ':id',
        name: 'MissionView',
        component: MissionView,
      },
    ]
  }
]