import { Commit, createStore } from 'vuex'

// const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
	state: {
		auth: false
	},
	mutations: {
		setAuth: (state: {auth: boolean}, auth: boolean) => state.auth = auth
	},
	actions: {
		setAuth: ({commit}: {commit: Commit}, auth: boolean) => commit('setAuth', auth)
	},
	getters: {},
	modules: {},
	// strict: debug,
  // plugins: debug ? [createLogger()] : []
})

export default store
