import Forum from './Forum.vue'
import ForumIndex from './views/Index.vue'
import ForumCategory from './views/Category.vue'
import ForumTopic from './views/Topic.vue'
import ForumThread from './views/Thread.vue'
import ForumThreadNew from './views/New.vue'
export const ForumRoutes = [
  {
    path: '/forum',
    component: Forum,
    children: [
      {
        path: '',
        name: 'ForumIndex',
        component: ForumIndex,
      },
      {
        path: ':catID',
        name: 'ForumCategory',
        component: ForumCategory,
      },
      {
        path: ':catID/:topID',
        name: 'ForumTopic',
        component: ForumTopic,
      },
      {
        path: ':catID/:topID/new',
        name: 'ForumThreadNew',
        component: ForumThreadNew,
      },
      {
        path: ':catID/:topID/:thID',
        name: 'ForumThread',
        component: ForumThread,
      }
    ]
  }
]