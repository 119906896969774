import User from './User.vue'
import UserEdit from './views/Edit.vue'
import UserIndex from './views/Index.vue'
import UserProfile from './views/Profile.vue'
import UserView from './views/View.vue'
export const UserRoutes = [
  {
    path: '/user',
    component: User,
    children: [
      {
        path: '',
        name: 'UserIndex',
        component: UserIndex,
      },
      {
        path: 'edit/:id',
        name: 'UserEdit',
        component: UserEdit,
      },
      {
        path: 'profile/:id',
        name: 'UserProfile',
        component: UserProfile,
      },
      {
        path: 'view/:id',
        name: 'UserView',
        component: UserView,
      }
    ]
  }
]