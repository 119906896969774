import Deployment from './Deployment.vue'
import DeploymentIndex from './views/Index.vue'
import DeploymentView from './views/View.vue'
export const DeploymentRoutes = [
  {
    path: '/theater/deployment',
    component: Deployment,
    children: [
      {
        path: '',
        name: 'DeploymentIndex',
        component: DeploymentIndex,
      },
      {
        path: ':id',
        name: 'DeploymentView',
        component: DeploymentView,
      },
    ]
  }
]