import Admin from './Admin.vue'
import AdminIndex from './views/Index.vue'
export const AdminRoutes = [
  {
    path: '/admin',
    component: Admin,
    children: [
      {
        path: '',
        name: 'AdminIndex',
        component: AdminIndex,
      }
    ]
  }
]
