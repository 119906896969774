import Campaign from './Campaign.vue'
import CampaignIndex from './views/Index.vue'
import CampaignView from './views/View.vue'
export const CampaignRoutes = [
  {
    path: '/theater/campaign',
    component: Campaign,
    children: [
      {
        path: '',
        name: 'CampaignIndex',
        component: CampaignIndex,
      },
      {
        path: ':id',
        name: 'CampaignView',
        component: CampaignView,
      },
    ]
  }
]