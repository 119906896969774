import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'

import 'bootstrap'
import '@fortawesome/fontawesome-pro/js/all'
import '@/assets/scss/main.scss'

// axios.defaults.baseURL = 'http://71.90.121.206:3000'
// axios.defaults.baseURL = 'http://129.146.185.99:3000'
axios.defaults.baseURL = 'http://www.a3phoenixgroup.com:3000'
// axios.defaults.baseURL = 'http://localhost:3000'
axios.defaults.withCredentials = true

createApp(App).use(store).use(router).mount('#app')
