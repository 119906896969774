import Blog from './Blog.vue'
import BlogIndex from './views/Index.vue'
import BlogView from './views/View.vue'
export const BlogRoutes = [
  {
    path: '/blog',
    component: Blog,
    children: [
      {
        path: '',
        name: 'BlogIndex',
        component: BlogIndex,
      },
      {
        path: ':id',
        name: 'BlogView',
        component: BlogView,
      },
    ]
  }
]