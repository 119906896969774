import Contract from './Contract.vue'
import ContractIndex from './views/Index.vue'
import ContractView from './views/View.vue'
export const ContractRoutes = [
  {
    path: '/theater/contract',
    component: Contract,
    children: [
      {
        path: '',
        name: 'ContractIndex',
        component: ContractIndex,
      },
      {
        path: ':id',
        name: 'ContractView',
        component: ContractView,
      },
    ]
  }
]