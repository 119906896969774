import Theater from './Theater.vue'
import TheaterIndex from './views/Index.vue'
import TheaterView from './views/View.vue'
import { CampaignRoutes } from './modules/campaign/routes'
import { ContractRoutes } from './modules/contract/routes'
import { DeploymentRoutes } from './modules/deployment/routes'
import { MissionRoutes } from './modules/mission/routes'
import { TrainingRoutes } from './modules/training/routes'
export const TheaterRoutes = [
  {
    path: '/theater',
    component: Theater,
    children: [
      {
        path: '',
        name: 'TheaterIndex',
        component: TheaterIndex,
      },
      {
        path: ':id',
        name: 'TheaterView',
        component: TheaterView,
      },
      ...CampaignRoutes,
      ...ContractRoutes,
      ...DeploymentRoutes,
      ...MissionRoutes,
      ...TrainingRoutes
    ]
  }
]