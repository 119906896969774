<template>
	<article class="card" id="phxg-card">
		<div class="card-body" id="phxg-search">
			<div class="input-group">
				<input type="text" class="form-control border-light" placeholder="Search" aria-label="Search" aria-describedby="btn-search">
				<button class="btn btn-outline-light rounded-0" type="button" id="btn-search"><i class="fad fa-search"></i></button>
			</div>
		</div>
	</article>
</template>
